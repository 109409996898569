.alert-container {
	display: flex;
	width: '100%';
	height: fit-content;
	max-width: 400px;

	-webkit-animation: mymove 1s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;

	animation: mymove 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

.alert-container-old {
	display: flex;
	flex-direction: column;
	position: fixed;
	right: 0;
	bottom: 0;
	width: 40vw;
	height: 400px;
	height: fit-content;
	max-width: 400px;
	z-index: 100000000;
	padding-right: 12px;

	-webkit-animation: mymove 1s;
	-webkit-animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;

	animation: mymove 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes mymove {
	from {
		bottom: -400px;
	}
	to {
		bottom: 0px;
	}
}

@keyframes mymove {
	from {
		bottom: -400px;
	}
	to {
		bottom: 0px;
	}
}
