.delete_confirmation_modal {
	margin-top: 14px !important;
}

.delete_confirmation_modal p {
	letter-spacing: 0.13px !important;
	color: rgba(26, 28, 33, 0.64) !important;
	font-size: 14px !important;
	line-height: 20px !important;
	margin: 0 0 10px 0 !important;
}

.button_gray_bg {
	background: #e4e4e4 !important;
	border-radius: 9px !important;
	font-size: 13px !important;
	letter-spacing: 0.13px !important;
	color: #666666 !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.06) !important;
}

/*Select style*/
.common_select_type select {
	border: 1px solid #70707045 !important;
	border-radius: 3px !important;
	padding: 7px 11px !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	font-size: 14px !important;
}
.common_select_type .MuiOutlinedInput-notchedOutline {
	border: 1px solid #70707045 !important;
}
.common_select_type:hover {
	border-color: #1492e6 !important;
}
/*Select style*/

/*Input style*/
.common_input_type input {
	background: #fafbfd !important;
	border: 1px solid #70707045 !important;
	border-radius: 3px !important;
	padding: 7px 11px !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	font-size: 14px !important;
}
.common_input_type::after,
.common_input_type::before {
	content: none !important;
}
.common_input_type input:hover,
.common_input_type input:active,
.common_input_type input:focus,
.common_input_type input:focus-visible {
	border-color: #1492e6 !important;
}
.common_input_type.Mui-disabled input.Mui-disabled {
	background: #f5f5f5 !important;
}
/*Input style*/

.common_input_type fieldset {
	border-color: #70707045 !important;
}

.MuiModal-root .common_input_type {
	max-width: 344px;
}

.common_input_type:hover fieldset,
.common_select_type:hover fieldset {
	border-color: #1492e6 !important;
}

/*Input label*/
.common_input_label {
	font-weight: bold !important;
	letter-spacing: 0.14px !important;
	color: #1a1c21 !important;
	transform: none !important;
	position: static !important;
}
/*Input label*/
