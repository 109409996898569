.common_input_type input {
	background: #fafbfd !important;
	border: 1px solid #70707045 !important;
	border-radius: 3px !important;
	padding: 7px 11px !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	font-size: 14px !important;
}
.common_input_type::after,
.common_input_type::before {
	content: none !important;
}
.common_input_type input:hover,
.common_input_type input:active,
.common_input_type input:focus,
.common_input_type input:focus-visible {
	border-color: #1492e6 !important;
}
.common_input_type.Mui-disabled input.Mui-disabled {
	background: #f5f5f5 !important;
}
.date_pick_input_type {
	background: #fafbfd !important;
	border: 1px solid #70707045 !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	width: 200px;
}
.date_pick_input_type input {
	background: #fafbfd !important;
	border-radius: 3px !important;
	padding: 9px 0 9px 10px;
	border: none;
	font-size: 14px;
}
.date_pick_input_type fieldset {
	border: none !important;
}
/*Input style*/

.common_input_type fieldset {
	border-color: #70707045 !important;
}

.MuiModal-root .common_input_type {
	max-width: 344px;
}

.common_input_type:hover fieldset,
.common_select_type:hover fieldset {
	border-color: #1492e6 !important;
}

.createExampleButton {
	background: #fff;
	width: 202px;
	height: 132px;
	border: 0.5px solid rgba(112, 112, 112, 0.27);
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	letter-spacing: 0.14px;
	color: #545454;
	font-size: 13px;
	line-height: 18px;
	font-weight: 700;
	margin-bottom: 10px;
	cursor: pointer;
}
.createExampleButton:hover {
	box-shadow: 0px 3px 6px #00000029;
}
.createExampleButton svg {
	width: 61px;
	height: 61px;
	color: rgba(0, 0, 0, 0.19);
}
.Download_template {
	cursor: pointer;
}
.create_data_labels {
	margin: 0 5px 0 0;
	letter-spacing: 0.14px;
	color: #545454;
	font-weight: 600;
	word-break: break-word;
	max-width: calc(100% - 39px);
}
.data_preview {
	display: flex;
	align-items: center;
}
.Export_btn button {
	width: 202px !important;
	margin-top: 10px !important;
}
