.light_blue_bg_btn {
	background: #1492e6 !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	border-radius: 9px !important;
}

.modal_bottom_btn {
	display: flex !important;
	align-items: flex-start !important;
	justify-content: flex-start !important;
	width: 100% !important;
	max-width: 100% !important;
	margin-top: 20px !important;
}

.modal_bottom_btn button {
	margin-right: 17px !important;
}

/*Select style*/
.common_select_type select {
	border: 1px solid #70707045 !important;
	border-radius: 3px !important;
	padding: 7px 11px !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	font-size: 14px !important;
}
.common_select_type .MuiOutlinedInput-notchedOutline {
	border: 1px solid #70707045 !important;
}
.common_select_type:hover {
	border-color: #1492e6 !important;
}
/*Select style*/

/*Input style*/
.common_input_type input {
	background: #fafbfd !important;
	border: 1px solid #70707045 !important;
	border-radius: 3px !important;
	padding: 7px 11px !important;
	max-width: 100% !important;
	letter-spacing: 0.17px !important;
	color: #1a1c21 !important;
	font-size: 14px !important;
}
.common_input_type::after,
.common_input_type::before {
	content: none !important;
}
.common_input_type input:hover,
.common_input_type input:active,
.common_input_type input:focus,
.common_input_type input:focus-visible {
	border-color: #1492e6 !important;
}
/*Input style*/

.common_input_type fieldset {
	border-color: #70707045 !important;
}

.common_input_type:hover fieldset,
.common_select_type:hover fieldset {
	border-color: #1492e6 !important;
}

.edit_table_title {
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.14px;
	color: #545454;
	width: 100%;
	border-bottom: 1px solid rgba(112, 112, 112, 0.29);
	padding: 0 0 5px 0;
	margin: 0 0 8px 0;
	font-weight: 500;
}

.fieldTypeRow {
	display: flex;
	align-items: center;
	margin: 0 0 14px 0;
}

.fieldTypeRow input[type='checkbox'] {
	width: 14px;
	height: 14px;
	margin: 0;
	padding: 0;
}

.fieldTypeRow svg {
	width: 20px;
}

.edit_table_cell_value {
	padding: 0 5px;
	letter-spacing: 0.14px;
	color: #545454;
	font-size: 13px;
	line-height: 18px;
	font-weight: 500;
	white-space: nowrap;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text_value {
	display: block;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-right: 5px;
}
